export const viewPorts = {
    mob: 560,
    sm: 640,
    md: 768,
    mb: 900,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    xxxl: 1700,
}
