export const ROUTES = {
    auth: 'Auth',
    login: 'AuthLogin',
    rates: 'Rates',
    detailRate: 'DetailRate',
    usersList: 'UsersList',
    userDetail: 'UserDetail',
    userReporting: 'UserReporting',
    paymentsHistory: 'PaymentsHistory',
    detailPaymentHistory: 'DetailPaymentHistory',
    numbers: 'Numbers',
    numberFees: 'NumberFees',
    numberPermissions: 'NumberPermissions',
    roles: 'Roles',
    detailRole: 'DetailRole',
    incomingCarrier: 'IncomingCarrier',
    detailIncomingCarrier: 'DetailIncomingCarrier',
    byoc: 'Byoc',
    bankOfNumbers: 'BankOfNumbers',
    bankOfNumberDetail: 'BankOfNumberDetail',
    manageAccounts: 'ManageAccounts',
    detailAccount: 'DetailAccount',
    managePaymentCards: 'managePaymentCards',
    detailPaymentCards: 'detailPaymentCard',
    adminUsers: 'AdminUsers',
    adminUserDetail: 'AdminUserDetail',
    activityLogs: 'ActivityLogs',
    detailActivityLog: 'DetailActivityLog',
    numberPool: 'NumberPool',
    detailNumberPool: 'DetailNumberPool',
    accountReport: 'AccountReport',
    dashboard: 'Dashboard',
}
