import { getAccessToken } from '@/helpers/auth-helper'

import { ROUTES } from '@/constants/routes'

export default function auth({ next }) {
    if (!getAccessToken()) {
        return next({ name: ROUTES.login })
    }

    return next()
}
