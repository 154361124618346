<template>
    <component :is="layout" />

    <notifications
        class="min-md:mr-5 min-md:mt-5 z-110 cursor-pointer"
        :max="3"
        width="90%"
        :position="positionNotification"
    />
</template>

<script setup>
import {
    computed,
    markRaw,
    watch,
    onMounted,
    ref,
    provide,
    onUnmounted,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@kyvg/vue3-notification'

import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'

import { useDefaultStore } from '@/store/defaultStore'
import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

import { ROUTES } from '@/constants/routes'
import { viewPorts } from '@/constants/viewPorts'

const defaultStore = useDefaultStore()
const appStore = useAppStore()
const authorizationStore = useAuthorizationStore()

const route = useRoute()
const router = useRouter()

const { notify } = useNotification()

let resizeObserver = null
const currentViewSize = ref(0)

const layout = computed(() => {
    const layout = `${route.meta.layout || 'empty'}-layout`

    const lookup = {
        'main-layout': MainLayout,
        'empty-layout': EmptyLayout,
    }

    return lookup.hasOwnProperty(layout) ? markRaw(lookup[layout]) : null
})

const user = computed(() => authorizationStore.user)

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const positionNotification = computed(() => (isMbView.value ? 'bottom center' : 'top right'))

onMounted(() => {
    appStore.loadCountries()
    appStore.loadTimezones()
    initResizeObserver()
})

provide('currentViewSize', currentViewSize)

function initResizeObserver() {
    resizeObserver = new ResizeObserver(() => {
        const name = Object.keys(viewPorts).find((name) => viewPorts[name] >= window.innerWidth)

        currentViewSize.value = viewPorts[name]
    })

    resizeObserver.observe(document.body)
}

watch(() => defaultStore.message, (text) => {
    if (text) {
        notify({ type: defaultStore.type, text, duration: defaultStore.duration })
        defaultStore.clearMessage()
    }
})

watch(user, (user) => {
    if (!user) {
        router.push({ name: ROUTES.login })
    }
})

onUnmounted(() => {
    resizeObserver.disconnect(document.body)
})
</script>
