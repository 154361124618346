<template>
    <teleport to="body">
        <div
            class="pl-2.5 z-100 relative"
            :style="popupPosition"
            @mouseenter="onMouseenter"
            @mouseleave="onMouseleave"
        >
            <div class="w-40.5 p-2 bg-purple-60 dark:bg-blue-770 rounded-1.25">
                <div>
                    <div
                        class="flex items-center cursor-pointer"
                        v-for="sub in route.subMenu"
                        :key="sub.name"
                    >
                        <router-link
                            :to="{ name: sub.routeName }"
                            class="w-full p-2 text-xs rounded-1.25 whitespace-nowrap"
                            :class="[ checkCurrentLink(sub) ? 'text-gray-600 dark:text-purple-140' : 'text-gray-370 dark:text-white hover:text-gray-600 hover:bg-purple-70 dark:hover:bg-blue-600' ]"
                            @click.stop
                        >
                            {{ sub.name }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
const props = defineProps({
    route: {
        type: Object,
        default: () => ({}),
    },
    popupPosition: {
        type: Object,
        default: () => ({}),
    },
    checkCurrentLink: {
        type: Function,
        default: () => (() => {}),
    },
})

const emits = defineEmits(['onMouseenter', 'onMouseleave'])

function onMouseenter() {
    emits('onMouseenter', props.route.id)
}

function onMouseleave() {
    emits('onMouseleave')
}

</script>
