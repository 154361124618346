<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.64746 19.3082C1.64746 18.7555 2.06893 18.3075 2.58884 18.3075H20.9334C21.4533 18.3075 21.8748 18.7555 21.8748 19.3082V20.9045C21.8748 21.0887 21.7343 21.2381 21.561 21.2381H1.96125C1.78795 21.2381 1.64746 21.0887 1.64746 20.9045V19.3082ZM2.58884 18.9747C2.41553 18.9747 2.27504 19.124 2.27504 19.3082V20.5709H21.2472V19.3082C21.2472 19.124 21.1067 18.9747 20.9334 18.9747H2.58884Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7939 8.12411C16.7939 7.93988 16.9344 7.79053 17.1077 7.79053H20.281C20.4543 7.79053 20.5948 7.93988 20.5948 8.12411V18.4709C20.5948 18.6551 20.4543 18.8044 20.281 18.8044C20.1077 18.8044 19.9672 18.6551 19.9672 18.4709V8.45768H17.4215V18.4709C17.4215 18.6551 17.281 18.8044 17.1077 18.8044C16.9344 18.8044 16.7939 18.6551 16.7939 18.4709V8.12411Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.154 9.39992C12.154 9.2157 12.2945 9.06635 12.4678 9.06635H15.6411C15.8144 9.06635 15.9549 9.2157 15.9549 9.39992V18.4709C15.9549 18.6552 15.8144 18.8045 15.6411 18.8045C15.4678 18.8045 15.3273 18.6552 15.3273 18.4709V9.7335H12.7816V18.4709C12.7816 18.6552 12.6411 18.8045 12.4678 18.8045C12.2945 18.8045 12.154 18.6552 12.154 18.4709V9.39992Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67401 10.5621C7.67401 10.3778 7.8145 10.2285 7.98781 10.2285H11.1611C11.3344 10.2285 11.4749 10.3778 11.4749 10.5621V18.4709C11.4749 18.6551 11.3344 18.8045 11.1611 18.8045C10.9878 18.8045 10.8473 18.6551 10.8473 18.4709V10.8956H8.3016V18.4709C8.3016 18.6551 8.16111 18.8045 7.98781 18.8045C7.8145 18.8045 7.67401 18.6551 7.67401 18.4709V10.5621Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.19405 12.5894C3.19405 12.4052 3.33454 12.2558 3.50784 12.2558H6.68114C6.85444 12.2558 6.99493 12.4052 6.99493 12.5894V18.4709C6.99493 18.6551 6.85444 18.8045 6.68114 18.8045C6.50783 18.8045 6.36734 18.6551 6.36734 18.4709V12.923H3.82164V18.4709C3.82164 18.6551 3.68115 18.8045 3.50784 18.8045C3.33454 18.8045 3.19405 18.6551 3.19405 18.4709V12.5894Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8373 4.72046C20.9726 4.83557 20.9945 5.04549 20.8862 5.18933C18.1342 8.84499 15.5758 11.013 12.6509 12.3669C9.73495 13.7167 6.47779 14.2457 2.35742 14.666C2.18491 14.6836 2.03164 14.5492 2.01509 14.3658C1.99854 14.1824 2.12496 14.0195 2.29747 14.0019C6.41147 13.5823 9.5824 13.0596 12.4003 11.7552C15.2093 10.455 17.6907 8.36642 20.3962 4.77248C20.5045 4.62864 20.702 4.60535 20.8373 4.72046Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2362 2.83606C22.3233 2.91106 22.3662 3.02977 22.3487 3.14747L21.9545 5.7935C21.9369 5.91121 21.8616 6.01003 21.757 6.05274C21.6523 6.09545 21.5342 6.07556 21.4471 6.00056L19.4885 4.31463C19.4014 4.23963 19.3585 4.12092 19.3761 4.00321C19.3936 3.88551 19.4689 3.78669 19.5735 3.74398L21.9263 2.78388C22.0309 2.74117 22.1491 2.76106 22.2362 2.83606ZM20.3058 4.15975L21.4196 5.1185L21.6438 3.61377L20.3058 4.15975Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.64746 19.3082C1.64746 18.7555 2.06893 18.3075 2.58884 18.3075H20.9334C21.4533 18.3075 21.8748 18.7555 21.8748 19.3082V20.9045C21.8748 21.0887 21.7343 21.2381 21.561 21.2381H1.96125C1.78795 21.2381 1.64746 21.0887 1.64746 20.9045V19.3082ZM2.58884 18.9747C2.41553 18.9747 2.27504 19.124 2.27504 19.3082V20.5709H21.2472V19.3082C21.2472 19.124 21.1067 18.9747 20.9334 18.9747H2.58884Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7939 8.12411C16.7939 7.93988 16.9344 7.79053 17.1077 7.79053H20.281C20.4543 7.79053 20.5948 7.93988 20.5948 8.12411V18.4709C20.5948 18.6551 20.4543 18.8044 20.281 18.8044C20.1077 18.8044 19.9672 18.6551 19.9672 18.4709V8.45768H17.4215V18.4709C17.4215 18.6551 17.281 18.8044 17.1077 18.8044C16.9344 18.8044 16.7939 18.6551 16.7939 18.4709V8.12411Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.154 9.39992C12.154 9.2157 12.2945 9.06635 12.4678 9.06635H15.6411C15.8144 9.06635 15.9549 9.2157 15.9549 9.39992V18.4709C15.9549 18.6552 15.8144 18.8045 15.6411 18.8045C15.4678 18.8045 15.3273 18.6552 15.3273 18.4709V9.7335H12.7816V18.4709C12.7816 18.6552 12.6411 18.8045 12.4678 18.8045C12.2945 18.8045 12.154 18.6552 12.154 18.4709V9.39992Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67401 10.5621C7.67401 10.3778 7.8145 10.2285 7.98781 10.2285H11.1611C11.3344 10.2285 11.4749 10.3778 11.4749 10.5621V18.4709C11.4749 18.6551 11.3344 18.8045 11.1611 18.8045C10.9878 18.8045 10.8473 18.6551 10.8473 18.4709V10.8956H8.3016V18.4709C8.3016 18.6551 8.16111 18.8045 7.98781 18.8045C7.8145 18.8045 7.67401 18.6551 7.67401 18.4709V10.5621Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.19405 12.5894C3.19405 12.4052 3.33454 12.2558 3.50784 12.2558H6.68114C6.85444 12.2558 6.99493 12.4052 6.99493 12.5894V18.4709C6.99493 18.6551 6.85444 18.8045 6.68114 18.8045C6.50783 18.8045 6.36734 18.6551 6.36734 18.4709V12.923H3.82164V18.4709C3.82164 18.6551 3.68115 18.8045 3.50784 18.8045C3.33454 18.8045 3.19405 18.6551 3.19405 18.4709V12.5894Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8373 4.72046C20.9726 4.83557 20.9945 5.04549 20.8862 5.18933C18.1342 8.84499 15.5758 11.013 12.6509 12.3669C9.73495 13.7167 6.47779 14.2457 2.35742 14.666C2.18491 14.6836 2.03164 14.5492 2.01509 14.3658C1.99854 14.1824 2.12496 14.0195 2.29747 14.0019C6.41147 13.5823 9.5824 13.0596 12.4003 11.7552C15.2093 10.455 17.6907 8.36642 20.3962 4.77248C20.5045 4.62864 20.702 4.60535 20.8373 4.72046Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2362 2.83606C22.3233 2.91106 22.3662 3.02977 22.3487 3.14747L21.9545 5.7935C21.9369 5.91121 21.8616 6.01003 21.757 6.05274C21.6523 6.09545 21.5342 6.07556 21.4471 6.00056L19.4885 4.31463C19.4014 4.23963 19.3585 4.12092 19.3761 4.00321C19.3936 3.88551 19.4689 3.78669 19.5735 3.74398L21.9263 2.78388C22.0309 2.74117 22.1491 2.76106 22.2362 2.83606ZM20.3058 4.15975L21.4196 5.1185L21.6438 3.61377L20.3058 4.15975Z" stroke="currentColor" stroke-width="0.4" stroke-linejoin="round"/>
    </svg>
</template>