<template>
    <div
        class="absolute pr-5 min-mb:pr-18 w-full h-app-bar flex items-center bg-white dark:bg-blue-800 border-b border-gray-100 dark:border-gray-760"
        :class="wrapperClass"
    >
        <div
            class="h-full flex items-center cursor-pointer"
            @click="showSideBars"
        >
            <MenuIcon class="ml-5 text-gray-370 dark:text-white block min-mb:hidden" />
        </div>

        <CustomBreadCrumbs/>
    </div>
</template>

<script setup>
import { computed } from 'vue'

import MenuIcon from '@/components/icons/MenuIcon.vue'
import CustomBreadCrumbs from '@/components/breadCrumbs/CustomBreadCrumbs.vue'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    marginClass: {
        type: Array,
        default: () => [],
    },
})

const emits = defineEmits(['showSideBars'])

const wrapperClass = computed(() => [
    ...props.marginClass,
    props.mini && 'min-mb:w-app-bar-min',
    !props.mini && 'min-mb:w-app-bar-max',
])

function showSideBars() {
    emits('showSideBars')
}
</script>
