import axios from '@/plugins/axios'

const getLogin = (formData) => axios.post('auth/login', formData)
const getLoginConfirm = ({ slug, formData }) => axios.post(`auth/login/${slug}`, formData)
const checkUserAccess = () => axios.post('auth/check')
const getLogout = () => axios.post('auth/logout')

export {
    getLogin,
    checkUserAccess,
    getLogout,
    getLoginConfirm,
}
