import axios from 'axios'
import { getAccessToken } from '@/helpers/auth-helper'

const axiosApi = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/api/`,
})

axiosApi.interceptors.request.use(
    (config) => {
        const token = getAccessToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },

    (error) => Promise.reject(error),
)

axiosApi.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'].indexOf('text/csv;') + 1) {
            return Promise.resolve(response)
        }
        return Promise.resolve(response.data)
    },
    (error) => {
        if (error?.response?.status) {}

        const message = error?.response ? error.response.data.message : error.message
        return Promise.resolve({
            success: false,
            message,
        })
    },
)

export default axiosApi
