<template>
    <div
        class="fixed flex flex-col justify-between bg-white-300 dark:bg-black-370 h-full pt-2.5 pb-5 transition-width custom-scroll-y"
        :class="[
            mini ? 'w-side-bar-main-mini' : 'w-side-bar-main',
            showMobileSideBars ? 'z-30' : '-left-offset-side-bar-main min-mb:left-0'
        ]"
        v-touch:swipe.left="swipeLeft"
    >
        <div>
            <SidebarTop
                :mini="mini"
                @changeMini="changeMini"
            />

            <CustomDivider
                class="my-5 mx-2.75"
                color="border-gray-100 dark:border-gray-760"
            />

            <div
                v-if="!mini"
                class="app-text-xs px-4 mb-4 whitespace-nowrap"
            >
                MANAGE SYSTEM
            </div>

            <RoutingSideBar
                :mini="mini"
                :showMobileSideBars="showMobileSideBars"
            />
        </div>

        <div>
            <template
                v-for="route in BOTTOM_MENU_ROUTES"
                :key="route.id"
            >
                <CustomRouterLink
                    class="relative group h-10 px-2.75 flex items-center gap-x-1.25 hover:bg-purple-70 hover:text-gray-370 dark:hover:text-white dark:hover:bg-blue-770 cursor-pointer before:h-0 before:absolute before:right-0 before:top-0 before:w-0.5 before:bg-purple-140 transition-colors before:transition-all"
                    :route="route"
                    :simpleRote="!!route.routeName"
                    :mini="mini"
                    @click="navigationActionHandler(route)"
                >
                    <div class="w-9.5 flex item-center justify-center text-gray-370 dark:text-white transition-colors">
                        <component :is="route.icon()" />
                    </div>

                    <div
                        v-if="!mini"
                        class="text-center text-3.25 whitespace-nowrap text-gray-370 dark:text-white transition-colors"
                    >
                        {{ route.name }}
                    </div>
                </CustomRouterLink>
            </template>
        </div>
    </div>
</template>

<script setup>
import RoutingSideBar from '@/components/navigation/RoutingSideBar/RoutingSideBar.vue'
import CustomDivider from '@/components/divider/CustomDivider.vue'
import SidebarTop from '@/components/navigation/Sidebar/SidebarTop.vue'
import CustomRouterLink from '@/components/navigation/CustomRouterLink.vue'

import { useAuthorizationStore } from '@/store/authorizationStore'

import { BOTTOM_MENU_ROUTES } from '@/constants/menu'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    showMobileSideBars: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:showMobileSideBars', 'update:mini'])

const authorizationStore = useAuthorizationStore()

function navigationActionHandler(route) {
    if (!route.hasOwnProperty('action')) {
        return
    }

    switch (route.action) {
    case NAVIGATION_ACTIONS.logout:
        authorizationStore.logout()
    }
}

function changeMini() {
    emits('update:mini', !props.mini)
}

function swipeLeft() {
    emits('update:showMobileSideBars', false)
}
</script>
