<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.91393 3.47266H4.12358C3.73698 3.47266 3.42358 3.78606 3.42358 4.17266V6.92376C3.42358 7.31036 3.73698 7.62376 4.12358 7.62376H6.91393C7.30053 7.62376 7.61393 7.31036 7.61393 6.92376V4.17266C7.61393 3.78606 7.30053 3.47266 6.91393 3.47266Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M13.3951 3.47266H10.6048C10.2182 3.47266 9.90479 3.78606 9.90479 4.17266V6.92376C9.90479 7.31036 10.2182 7.62376 10.6048 7.62376H13.3951C13.7817 7.62376 14.0951 7.31036 14.0951 6.92376V4.17266C14.0951 3.78606 13.7817 3.47266 13.3951 3.47266Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M19.8763 3.47266H17.086C16.6994 3.47266 16.386 3.78606 16.386 4.17266V6.92376C16.386 7.31036 16.6994 7.62376 17.086 7.62376H19.8763C20.2629 7.62376 20.5763 7.31036 20.5763 6.92376V4.17266C20.5763 3.78606 20.2629 3.47266 19.8763 3.47266Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M6.91393 9.92578H4.12358C3.73698 9.92578 3.42358 10.2392 3.42358 10.6258V13.3769C3.42358 13.7635 3.73698 14.0769 4.12358 14.0769H6.91393C7.30053 14.0769 7.61393 13.7635 7.61393 13.3769V10.6258C7.61393 10.2392 7.30053 9.92578 6.91393 9.92578Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M13.3951 9.92578H10.6048C10.2182 9.92578 9.90479 10.2392 9.90479 10.6258V13.3769C9.90479 13.7635 10.2182 14.0769 10.6048 14.0769H13.3951C13.7817 14.0769 14.0951 13.7635 14.0951 13.3769V10.6258C14.0951 10.2392 13.7817 9.92578 13.3951 9.92578Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M19.8763 9.92578H17.086C16.6994 9.92578 16.386 10.2392 16.386 10.6258V13.3769C16.386 13.7635 16.6994 14.0769 17.086 14.0769H19.8763C20.2629 14.0769 20.5763 13.7635 20.5763 13.3769V10.6258C20.5763 10.2392 20.2629 9.92578 19.8763 9.92578Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M6.91393 16.3789H4.12358C3.73698 16.3789 3.42358 16.6923 3.42358 17.0789V19.83C3.42358 20.2166 3.73698 20.53 4.12358 20.53H6.91393C7.30053 20.53 7.61393 20.2166 7.61393 19.83V17.0789C7.61393 16.6923 7.30053 16.3789 6.91393 16.3789Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M13.3951 16.3789H10.6048C10.2182 16.3789 9.90479 16.6923 9.90479 17.0789V19.83C9.90479 20.2166 10.2182 20.53 10.6048 20.53H13.3951C13.7817 20.53 14.0951 20.2166 14.0951 19.83V17.0789C14.0951 16.6923 13.7817 16.3789 13.3951 16.3789Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M19.8763 16.3789H17.086C16.6994 16.3789 16.386 16.6923 16.386 17.0789V19.83C16.386 20.2166 16.6994 20.53 17.086 20.53H19.8763C20.2629 20.53 20.5763 20.2166 20.5763 19.83V17.0789C20.5763 16.6923 20.2629 16.3789 19.8763 16.3789Z" stroke="currentColor" stroke-width="1.3"/>
    </svg>
</template>