import axios from '@/plugins/axios'

const getAllAccounts = () => axios.get('accounts/all')
const getAccounts = (params) => axios.get('accounts/paginated', { params })
const getAccountReport = ({ slug, params }) => axios.get(`accounts/${slug}/calls-report`, { params })
const getAccountStatuses = () => axios.get('account-statuses')
const updateAccount = ({ slug, formData }) => axios.patch(`accounts/${slug}`, formData)
const getDetailAccount = (slug) => axios.get(`accounts/${slug}`)
const increaseBalanceAccount = ({ slug, formData }) => axios.post(`accounts/${slug}/balances`, formData)
const decreaseBalanceAccount = ({ slug, formData }) => axios.delete(`accounts/${slug}/balances`, { data: formData })

const getAccountFiltersAndSorting = () => axios.get('accounts/filters-and-sorting')

const createAccountNotification = (formData) => axios.post('accounts/notifications', formData)

const removeAccountFiles = (formData) => axios.delete('accounts/files', { data: formData })

export {
    getAllAccounts,
    getAccounts,
    updateAccount,
    getDetailAccount,
    increaseBalanceAccount,
    decreaseBalanceAccount,
    getAccountStatuses,
    getAccountFiltersAndSorting,
    removeAccountFiles,
    createAccountNotification,
    getAccountReport,
}
