<template>
    <div @click="changeMini">
        <div
            class="group flex items-center h-7.5 cursor-pointer"
            :class="mini ? 'px-2.5 justify-center' : 'pl-5 pr-2.5 justify-between'"
        >
            <div class="flex items-center">
                <div class="w-5.5">
                    <MenuLogo />
                </div>

                <div
                    v-if="!mini"
                    class="pl-3 text-purple font-semibold text-sm select-none whitespace-nowrap"
                >
                    Dialics Admin
                </div>
            </div>

            <div
                v-if="!mini && !isMbView"
                class="w-6 h-6 flex items-center justify-center rounded-1.25 group hover:bg-purple-70 dark:hover:bg-gray-750 cursor-pointer"
            >
                <DoubleArrowIcon class="text-gray-200 dark:text-gray-400 dark:group-hover:text-gray-200 rotate-180" />
            </div>
        </div>

        <div
            v-if="mini && !isMbView"
            class="flex w-6 h-6 mt-2 mx-auto items-center justify-center rounded-1.25 hover:bg-purple-70 dark:hover:bg-gray-750 cursor-pointer"
        >
            <DoubleArrowIcon class="text-gray-200 dark:text-gray-400 dark:group-hover:text-gray-200" />
        </div>
    </div>

</template>

<script setup>
import { computed, inject } from 'vue'

import MenuLogo from '@/components/icons/logo/DefaultLogo.vue'
import DoubleArrowIcon from '@/components/icons/arrow/DoubleArrowIcon.vue'

import { viewPorts } from '@/constants/viewPorts'

defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['changeMini'])

const currentViewSize = inject('currentViewSize')

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

function changeMini() {
    if (!isMbView.value) {
        emits('changeMini')
    }
}
</script>
