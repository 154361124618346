<template>
    <div class="flex h-full w-full">
        <AppBar
            :mini="mini"
            :marginClass="marginClass"
            @showSideBars="showSideBars"
        />

        <Sidebar
            v-model:mini="mini"
            v-model:showMobileSideBars="showMobileSideBars"
        />

        <div
            class="w-full overflow-hidden transition-spacing bg-white dark:bg-blue-800"
            :class="marginClass"
            @click="hideSideBars"
        >
            <div class="mt-from-app-bar h-main-container relative w-full transition-all">
                <router-view v-slot="{ Component, route }">
                    <transition name="fade">
                        <div class="h-full" :key="route.name">
                            <component :is="Component" />
                        </div>
                    </transition>
                </router-view>

                <transition name="fade">
                    <div
                        v-if="appStore.routeIsLoading"
                        class="absolute top-0 w-full h-full flex items-center justify-center z-120 bg-black/4"
                    >
                        <MainLoader />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref, computed, watch, onMounted, provide,
} from 'vue'

import { useRoute } from 'vue-router'

import Sidebar from '@/components/navigation/Sidebar/Sidebar.vue'
import AppBar from '@/components/navigation/AppBar.vue'
import MainLoader from '@/components/mainLoader/MainLoader.vue'

import { getBarsPositions, setBarsPositions, addEventListenerStorage } from '@/helpers/app-local-storage-helper'

import { useAppStore } from '@/store/appStore'

const appStore = useAppStore()

const route = useRoute()

const mini = ref(false)

const showMobileSideBars = ref(false)

provide('mini', mini)

const marginClass = computed(() => [
    mini.value && 'min-mb:ml-from-side-bars-min',
    !mini.value && 'min-mb:ml-from-side-bars-max',
])

onMounted(() => {
    getSideBarsPositions()
    addEventListenerStorage()
})

function getSideBarsPositions() {
    const positions = getBarsPositions()

    mini.value = positions.mini
}

function showSideBars() {
    mini.value = false

    showMobileSideBars.value = true
}

function hideSideBars() {
    showMobileSideBars.value = false
}

watch(() => ({ mini: mini.value }), (positions) => {
    setBarsPositions(positions)
})

watch(route, () => {
    hideSideBars()
})
</script>
